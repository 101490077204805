import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['activeStatusOption', 'inactiveStatusOption']

  initialize () {
    this.activeStatusOptionTargets.forEach(item => {
      item.click()
    })
    this.inactiveStatusOptionTarget.form.requestSubmit()
  }

  markAllStatus (element) {
    this.activeStatusOptionTargets.forEach(item => {
      if (item.checked === false) item.checked = true
    })
    this.inactiveStatusOptionTarget.checked = false
    element.target.form.requestSubmit()
  }

  activeChanged (element) {
    if (this.inactiveStatusOptionTarget.checked === true) this.inactiveStatusOptionTarget.checked = false
    element.target.form.requestSubmit()
  }

  inactiveChanged (element) {
    this.activeStatusOptionTargets.forEach(item => {
      item.checked = false
    })
    element.target.form.requestSubmit()
  }
}
