import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  connect () {
    this.element.addEventListener('submit', this.disableButton.bind(this))
  }

  disableButton (event) {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.textContent = 'Submitting...'
    }
  }
}
